import {
  axiosGetRequest,
  axiosPostRequest,
} from "../../../../../../../services/Request";
import environment from "../../../../../../../services/environment";
import { noAnswerDegreeId, useAnswerContext } from "../../answerContext";
import usePrevAgencyAnswer from "./usePrevAgencyAnswer";

const usePrevReviewerAnswer = () => {
  const { getPrevAgencyAnswer } = usePrevAgencyAnswer();

  const {
    setLoadPreReviewerAnswer,
    setNewReviewerAnswer,
    setReviewerAnswer,
    reviewerDegree,
    newReviewerAnswer,
    setReviewerDegree,
    getSelfEvaluation,
  } = useAnswerContext();

  const getDegrees = async (standardId) => {
    let degreeValues = [];
    const degreesResult = await axiosGetRequest(
      environment.getCommitmentLevels,
      {
        standardId,
      }
    );

    if (degreesResult?.success) {
      degreeValues = degreesResult?.result?.map((degree) => ({
        ...degree,
        label: degree.title,
        value: degree.id,
      }));
    }

    const surveyDegrees = [...degreeValues];

    return surveyDegrees;
  };
  // const { currentStage } = useContext(StageContext);

  const getPrevReviewerAnswer = async (
    standardId,
    entityId,
    currentStage,
    reviewerId
  ) => {
    setLoadPreReviewerAnswer(true);

    const agencyAnswer = await getPrevAgencyAnswer(
      standardId,
      entityId,
      currentStage
    );
    setNewReviewerAnswer(agencyAnswer);

    const res = await axiosGetRequest(environment.getReviewerAnswer, {
      AgencyId: entityId,
      StageId: currentStage,
      StandardId: standardId,
      ReviewerId: reviewerId || localStorage.getItem("userId"),
    });

    if (res?.result) {
      const reviewerRequirementsAnswer =
        agencyAnswer?.standardRequirements?.map((requirement) => {
          const reqAnswer =
            res?.result?.reviewerAnswer?.reviewerRequirementAnswers?.find(
              (reqAns) => reqAns.standardRequirementId === requirement?.id
            );

          if (reqAnswer?.reviewerSubRequirementAnswers) {
            const { reviewerSubRequirementAnswers, ...reqs } = reqAnswer;

            const subRequirements = requirement?.subStandardRequirements?.map(
              (subReq) => {
                const sub = reviewerSubRequirementAnswers?.find(
                  (item) => subReq?.id === item?.subStandardRequirementId
                );

                return {
                  ...subReq,
                  ...sub,
                  id: subReq.id,
                };
              }
            );

            return {
              ...requirement,
              ...reqs,
              id: requirement.id,
              recommendation: "",
              subStandardRequirements: [...subRequirements],
            };
          }

          const subStandardRequirements =
            requirement?.subStandardRequirements?.map((item) => ({
              ...item,
              decision: 0,
              donotApply: false,
            }));
          return {
            ...requirement,
            decision: 0,
            donotApply: false,
            subStandardRequirements,
          };
        });

      getSelfEvaluation(
        standardId,
        reviewerRequirementsAnswer,
        entityId,
        "reviewer"
      );

      const agencyPrevUploadedFilesAnswer = agencyAnswer?.standardProofs?.map(
        (proofFile) => {
          const proofNote =
            res?.result?.reviewerAnswer?.surveyReviewerAnswerStandardProofs?.find(
              (proofAns) =>
                proofAns.standardProofId === proofFile?.standardProofId
            );

          

          return {
            ...proofFile,
            ...proofNote,
            reviewerProofNotes: proofNote?.reviewerProofNotes || "",
            pastSelectedFiles: [],
          };
        }
      );

      const templateList = await axiosGetRequest(
        environment.getAllNoteTemplate
      );

      // * Get and select selected reviewer degree
      const surveyDegrees = (await getDegrees(standardId)).filter(
        (item) => item?.id !== noAnswerDegreeId
      );

      const donotApplyDegree =
        surveyDegrees?.filter((item) => item?.title == "لا ينطبق")?.[0] || "";
      const noCommitmentDegree =
        surveyDegrees?.filter((item) => item?.title == "عدم التزام")?.[0] || "";

      const checkedReviewerDegree =
        surveyDegrees?.filter(
          (item) =>
            item?.id == res?.result?.reviewerAnswer?.finalCommitmentLevel?.id
        )?.[0] || null;

      setReviewerDegree({
        ...reviewerDegree,
        reviewerSelfEvaluationCommitmentLevel:
          res?.result?.reviewerAnswer?.selfEvaluationCommitmentLevel ||
          noCommitmentDegree,
        reviewerSelfEvaluationDegree:
          res?.result?.reviewerAnswer?.selfEvaluationDegree || 0,
      });

      const pocOfficerRequirementsAnswer =
        agencyAnswer?.standardRequirements?.map((requirement) => {
          const reqAnswer =
            res?.result?.lastAnswer?.pocOfficerRequirementAnswers?.find(
              (reqAns) => reqAns.standardRequirementId === requirement?.id
            );

          if (reqAnswer?.pocOfficerSubRequirementAnswers) {
            const { pocOfficerSubRequirementAnswers, ...reqs } = reqAnswer;

            const subRequirements = requirement?.subStandardRequirements?.map(
              (subReq) => {
                const sub = pocOfficerSubRequirementAnswers?.find(
                  (item) => subReq?.id === item?.subStandardRequirementId
                );

                return {
                  ...sub,
                  title: subReq?.title,
                  id: subReq.id,
                };
              }
            );

            return {
              ...reqs,
              title: requirement?.title,
              id: requirement.id,
              recommendation: "",
              subStandardRequirements: [...subRequirements],
            };
          }

          const subStandardRequirements =
            requirement?.subStandardRequirements?.map((item) => ({
              ...item,
              decision: 0,
              donotApply: false,
            }));
          return {
            ...requirement,
            decision: 0,
            donotApply: false,
            subStandardRequirements,
          };
        });
      const expertRequirementsAnswer = agencyAnswer?.standardRequirements?.map(
        (requirement) => {
          const reqAnswer =
            res?.result?.lastAnswer?.expertRequirementAnswers?.find(
              (reqAns) => reqAns.standardRequirementId === requirement?.id
            );

          if (reqAnswer?.expertSubRequirementAnswers) {
            const { expertSubRequirementAnswers, ...reqs } = reqAnswer;

            const subRequirements = requirement?.subStandardRequirements?.map(
              (subReq) => {
                const sub = expertSubRequirementAnswers?.find(
                  (item) => subReq?.id === item?.subStandardRequirementId
                );

                return {
                  ...sub,
                  title: subReq?.title,
                  id: subReq.id,
                };
              }
            );

            return {
              ...reqs,
              title: requirement?.title,
              id: requirement.id,
              recommendation: "",
              subStandardRequirements: [...subRequirements],
            };
          }

          const subStandardRequirements =
            requirement?.subStandardRequirements?.map((item) => ({
              ...item,
              decision: 0,
              donotApply: false,
            }));
          return {
            ...requirement,
            decision: 0,
            donotApply: false,
            subStandardRequirements,
          };
        }
      );
      // 

      const answer = {
        ...newReviewerAnswer,
        standardRequirements: reviewerRequirementsAnswer,
        checkedReviewerDegree,
        expertRequirementsAnswer,
        pocOfficerRequirementsAnswer,
        surveyDegrees,
        standardProofs: agencyPrevUploadedFilesAnswer,
        templateList: templateList?.result?.items,
        agencyFinalCommitmentLevel: agencyAnswer?.agencyDonotApply
          ? donotApplyDegree
          : res?.result?.lastAnswer?.agencyFinalCommitmentLevel ||
            noCommitmentDegree ||
            null,
        agencySelfEvaluationCommitmentLevel:
          res?.result?.lastAnswer?.agencySelfEvaluationCommitmentLevel,
        reviewerAnswerNote:
          res?.result?.reviewerAnswer?.reviewerAnswerNote || "",
        reviewerSelectedTemplate:
          res?.result?.reviewerAnswer?.noteForAgency?.template ||
          res?.result?.reviewerAnswer?.noteForAgency?.content ||
          "الملاحظات:",
        noteForAgency:
          res?.result?.reviewerAnswer?.noteForAgency?.content || "",
        // noteForAgency: res?.result?.lastAnswer?.noteForAgency?.content || "",
        needVisit: res?.result?.reviewerAnswer?.needVisit,
        visitNote: res?.result?.reviewerAnswer?.visitNote,

        templateIdForAgency:
          res?.result?.reviewerAnswer?.noteForAgency?.noteTemplate?.id,

        reviewerRequirementAnswers: res?.result?.lastAnswer.reviewerRequirementAnswers,
        reviewerNoteTemplate: res?.result?.lastAnswer?.reviewerNoteForAgency?.noteTemplate?.templateContent || "",
        reviewerNotContent: res?.result?.lastAnswer?.reviewerNoteForAgency?.content,
        reviewerNoteForAgency:
          (res?.result?.lastAnswer?.reviewerNoteForAgency?.noteTemplate
            ?.templateContent || "") +
          (res?.result?.lastAnswer?.reviewerNoteForAgency?.unCheckRequirement ||
            "") +
          (res?.result?.lastAnswer?.reviewerNoteForAgency?.content || "") +
          (res?.result?.lastAnswer?.reviewerNoteForAgency?.template || ""),

        expertRequirementAnswers: res?.result?.lastAnswer.expertRequirementAnswers,
        expertNoteTemplate: res?.result?.lastAnswer?.expertNoteForAgency?.noteTemplate?.templateContent || "",
        expertNotContent: res?.result?.lastAnswer?.expertNoteForAgency?.content,
        expertNoteForAgency:
          (res?.result?.lastAnswer?.expertNoteForAgency?.noteTemplate
            ?.templateContent || "") +
          (res?.result?.lastAnswer?.expertNoteForAgency?.unCheckRequirement ||
            "") +
          (res?.result?.lastAnswer?.expertNoteForAgency?.content || "") +
          (res?.result?.lastAnswer?.expertNoteForAgency?.template || ""),
        
        pocOfficerNoteTemplate: res?.result?.lastAnswer?.pocOfficerNoteForAgency?.noteTemplate?.templateContent || "",
        pocOfficerNoteContent: res?.result?.lastAnswer?.pocOfficerNoteForAgency?.content,
        pocOfficerRequirementAnswers: res?.result?.lastAnswer.pocOfficerRequirementAnswers,
        pocOfficerNoteForAgency:
          (res?.result?.lastAnswer?.pocOfficerNoteForAgency?.noteTemplate
            ?.templateContent || "") +
          (res?.result?.lastAnswer?.pocOfficerNoteForAgency
            ?.unCheckRequirement || "") +
          (res?.result?.lastAnswer?.pocOfficerNoteForAgency?.content || "") +
          (res?.result?.lastAnswer?.pocOfficerNoteForAgency?.template || ""),
      };
      setNewReviewerAnswer(answer);
      setReviewerAnswer(structuredClone(answer));
    }

    setLoadPreReviewerAnswer(false);
  };

  return { getPrevReviewerAnswer };
};

export default usePrevReviewerAnswer;
