import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  isAdmin,
  isExpertManager,
  isInquiryManager,
} from "../../../../../../../helpers/isPermission";
import { useAnswerContext } from "../../answerContext";
import ReadOnlyRequirements from "../../components/readOnlyRequirements";
import StandardAnswerRequirement from "../../standardAnswerRequirements";
import MainRequirement from "../../standardAnswerRequirements/ui/mainRequirement";
import SubRequirement from "../../standardAnswerRequirements/ui/subRequirement";

const ExpertManagerRequirementAnswerPhase4 = ({ setMenuElement }) => {
  const { standardId, entityId } = useParams();
  const {
    getSelfEvaluation,
    newExpertManagerAnswer,
    setNewExpertManagerAnswer,
  } = useAnswerContext();

  const [selectedRequirementsTab, setSelectedRequirementsTab] =
    useState("expertManager");

  const handleRequirementChange = (requirement, property = "decision") => {
    const updatedRequirements =
      newExpertManagerAnswer?.standardRequirements?.map((item) => {
        if (item?.id === requirement?.id) {
          const subStandardRequirements =
            requirement?.subStandardRequirements?.map((subReq) => {
              if(subReq.isDisabled) return subReq;
              return property === "decision"
                ? {
                    ...subReq,
                    decision: requirement?.decision ? 0 : 1,
                    donotApply: 0,
                  }
                : {
                    ...subReq,
                    donotApply: !requirement?.donotApply,
                    decision: !requirement?.donotApply
                      ? 0
                      : requirement?.decision,
                  };
            });

          return property === "decision"
            ? {
                ...requirement,
                subStandardRequirements,
                decision: requirement?.decision ? 0 : 1,
                donotApply: 0,
              }
            : {
                ...requirement,
                subStandardRequirements,
                donotApply: !requirement?.donotApply,
                decision: !requirement?.donotApply ? 0 : requirement?.decision,
              };
        }

        return item;
      });

    getSelfEvaluation(
      standardId,
      updatedRequirements,
      entityId,
      "expertManager"
    );

    setNewExpertManagerAnswer({
      ...newExpertManagerAnswer,
      standardRequirements: updatedRequirements,
    });
  };

  const handleSubRequirementChange = (
    requirement,
    subReqId,
    property = "decision"
  ) => {
    const updatedSubRequirements =
      newExpertManagerAnswer?.standardRequirements?.map((item) => {
        if (item?.id === requirement?.id) {
          const newSubReqs = requirement?.subStandardRequirements?.map(
            (subRequire) => {
              if (subReqId === subRequire?.id) {
                return property === "decision"
                  ? {
                      ...subRequire,
                      decision: subRequire?.decision ? 0 : 1,
                      donotApply: 0,
                    }
                  : {
                      ...subRequire,
                      donotApply: !subRequire?.donotApply,
                      decision: !subRequire?.donotApply
                        ? 0
                        : subRequire?.decision,
                    };
              }

              return subRequire;
            }
          );

          const isAllSubReqChecked = newSubReqs?.every(
            (value) => value?.decision == 1
          );
          const isAllSubReqDonotApply = newSubReqs?.every(
            (value) => value?.donotApply === true
          );

          if (isAllSubReqChecked) requirement.decision = 1;
          else requirement.decision = 0;
          if (isAllSubReqDonotApply) requirement.donotApply = true;
          else requirement.donotApply = false;

          return { ...requirement, subStandardRequirements: newSubReqs };
        }

        return item;
      });
    getSelfEvaluation(
      standardId,
      updatedSubRequirements,
      entityId,
      "expertManager"
    );

    setNewExpertManagerAnswer({
      ...newExpertManagerAnswer,
      standardRequirements: updatedSubRequirements,
    });
  };

  const handleSelectAllReqs = (action = "selectAll") => {
    const updatedRequirements =
      newExpertManagerAnswer?.standardRequirements?.map((req) => {
        if (req?.isDisabled) return req;
        const subStandardRequirements = req?.subStandardRequirements?.map(
          (subReq) => {
            if (subReq?.isDisabled) return subReq;
            return {
              ...subReq,
              donotApply: 0,
              decision: action === "selectAll" ? 1 : 0,
            };
          }
        );

        return {
          ...req,
          subStandardRequirements,
          donotApply: 0,
          decision: action === "selectAll" ? 1 : 0,
        };
      });

    getSelfEvaluation(
      standardId,
      updatedRequirements,
      entityId,
      "expertManager"
    );

    setNewExpertManagerAnswer({
      ...newExpertManagerAnswer,
      standardRequirements: updatedRequirements,
    });
  };

  const renderedExpertManagerRequirements =
    newExpertManagerAnswer?.standardRequirements?.length > 0 &&
    newExpertManagerAnswer?.standardRequirements?.map((requirement) => {
      const hasSubRequirements =
        requirement?.subStandardRequirements?.length > 0;

      return (
        <>
          <MainRequirement
            key={requirement?.id}
            title={requirement?.title}
            hasSubRequirements={hasSubRequirements}
            checked={requirement?.decision}
            requirementDonotApplyChecked={requirement?.donotApply}
            onChange={() => {
              handleRequirementChange(requirement);
            }}
            handleDonotApplyRequirementChange={() =>
              handleRequirementChange(requirement, "donotApply")
            }
            readOnly={requirement?.isDisabled}
            disableDonotApply={requirement?.isDisabled}
          >
            {hasSubRequirements &&
              requirement?.subStandardRequirements?.map((subRequirement) => {
                return (
                  <>
                    <SubRequirement
                      key={subRequirement?.id}
                      title={subRequirement?.title}
                      checked={subRequirement?.decision}
                      onChange={() =>
                        handleSubRequirementChange(
                          requirement,
                          subRequirement?.id
                        )
                      }
                      subRequirementDonotApplyChecked={
                        subRequirement?.donotApply
                      }
                      handleDonotApplySubRequirementChange={() =>
                        handleSubRequirementChange(
                          requirement,
                          subRequirement?.id,
                          "donotApply"
                        )
                      }
                      readOnly={subRequirement?.isDisabled}
                      disableDonotApply={subRequirement?.isDisabled}
                    />
                    {/* <div className="px-6">
                      <AppTextarea
                        placeholder="اكتب توصية"
                        style={{
                          marginBottom: "24px",
                          backgroundColor: "#fafafa",
                        }}
                        value={subRequirement?.note || ""}
                        onChange={(e) =>
                          handleSubRequirementNoteChange(
                            e,
                            requirement,
                            subRequirement?.subStandardRequirementId
                          )
                        }
                      />
                    </div> */}
                  </>
                );
              })}
          </MainRequirement>

          {/* <div className="px-5 w-full">
            <AppTextarea
              placeholder="اكتب توصية"
              style={{ marginBottom: "24px" }}
              value={requirement?.note || ""}
              onChange={(e) => handleRequirementNoteChange(e, requirement)}
            />
          </div> */}
        </>
      );
    });

  return (
    <div>
      <StandardAnswerRequirement setMenuElement={setMenuElement}>
        <div className="flex  pb-5 w-full">
          <p
            className={
              selectedRequirementsTab == "agency"
                ? "bg-indigo-200 px-3 pb-2 rounded ml-4 cursor-pointer"
                : "bg-neutral-100 px-3 pb-2 rounded ml-4 cursor-pointer"
            }
            onClick={() => setSelectedRequirementsTab("agency")}
          >
            الجهة
          </p>
          <p
            className={
              selectedRequirementsTab == "reviewer"
                ? "bg-indigo-200 px-3 pb-2 rounded ml-4 cursor-pointer"
                : "bg-neutral-100 px-3 pb-2 rounded ml-4 cursor-pointer"
            }
            onClick={() => setSelectedRequirementsTab("reviewer")}
          >
            المدقق
          </p>

          <p
            className={
              selectedRequirementsTab == "expert"
                ? "bg-indigo-200 px-3 pb-2 rounded ml-4 cursor-pointer"
                : "bg-neutral-100 px-3 pb-2 rounded ml-4 cursor-pointer"
            }
            onClick={() => setSelectedRequirementsTab("expert")}
          >
            الخبير
          </p>
          <p
            className={
              selectedRequirementsTab == "pocOfficer"
                ? "bg-indigo-200 px-3 pb-2 rounded ml-4 cursor-pointer"
                : "bg-neutral-100 px-3 pb-2 rounded ml-4 cursor-pointer"
            }
            onClick={() => setSelectedRequirementsTab("pocOfficer")}
          >
            ضابط الاتصال
          </p>
          <p
            className={
              selectedRequirementsTab == "expertManager"
                ? "bg-indigo-800 text-white px-3 pb-2 rounded ml-4 cursor-pointer"
                : "bg-emerald-50 px-3 pb-2 rounded ml-4 cursor-pointer"
            }
            onClick={() => setSelectedRequirementsTab("expertManager")}
          >
            رئيس الخبراء
          </p>
        </div>

        {selectedRequirementsTab === "reviewer" && (
          <ReadOnlyRequirements
            requirements={newExpertManagerAnswer?.reviewerRequirementsAnswer}
          />
        )}
        {selectedRequirementsTab === "expert" && (
          <ReadOnlyRequirements
            requirements={newExpertManagerAnswer?.expertRequirementsAnswer}
          />
        )}
        {selectedRequirementsTab === "pocOfficer" && (
          <ReadOnlyRequirements
            requirements={newExpertManagerAnswer?.pocOfficerRequirementsAnswer}
          />
        )}

        {!isExpertManager &&
          (isAdmin || isInquiryManager) &&
          selectedRequirementsTab === "expertManager" && (
            <ReadOnlyRequirements
              requirements={newExpertManagerAnswer?.standardRequirements}
            />
          )}
        {isExpertManager &&
          !isAdmin &&
          !isInquiryManager &&
          selectedRequirementsTab === "expertManager" && (
            <>
              <div className="flex justify-end w-full gap-4">
                <p
                  onClick={() => handleSelectAllReqs()}
                  className="mb-6 cursor-pointer text-end text-emerald-500"
                >
                  تحديد الكل
                </p>
                <p
                  onClick={() => handleSelectAllReqs("cancel")}
                  className="mb-6 cursor-pointer text-end text-red-800"
                >
                  الغاء تحديد الكل
                </p>
              </div>
              {renderedExpertManagerRequirements}
            </>
          )}
      </StandardAnswerRequirement>
    </div>
  );
};

export default ExpertManagerRequirementAnswerPhase4;
