import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import AppLoader from "../../../../../../../components/loader";
import Tabs from "../../../../../../../components/Tabs/tabs";
import { StageContext } from "../../../../../../../contexts/stageContext";
import environment from "../../../../../../../services/environment";
import { axiosGetRequest } from "../../../../../../../services/Request";
import {
  IExpertEvaluation,
  IStageOneAgencyAnswer,
  IStanderWithRequirement,
} from "../../../types";
import { useAnswerContext } from "../../answerContext";
import NextBtn from "../../components/buttons/nextBtn";
import SaveBtn from "../../components/buttons/saveBtn";
import StandardAnswerData from "../../standardAnswerData";
import StandardAnswerNotes from "../../standardAnswerNotes";
import StandardAnswerRequirement from "../../standardAnswerRequirements";
import useCreateAgencyAnswer from "../useCreateAnswer/useCreateAgencyAnswer";
import usePrevAgencyAnswer from "../useGetPrevAnswer/usePrevAgencyAnswer";
import AgencyFinalAnswer from "./agencyFinalAnswer";
import AgencyNoteTemplate from "./agencyNoteTemplates";
import AgencyProofFiles from "./agencyProofFiles";
import AgencyRequirementAnswer from "./agencyRequirementAnswer";
import {
  trAgencyFinalAnswer,
  trAgencyRequirementAnswer,
  trExpertFinalAnswer,
  trExpertRequirementAnswer,
} from "./transformers";
import AgencyFinalAnswerViewOnly from "./widgets/agencyFinalAnswerViewOnly";
import AgencyProofFilesViewOnly from "./widgets/AgencyProofFilesViewOnly";
import AgencyRequirementAnswerStage3 from "./widgets/agencyRequirementAnswerStage3";
import AgencyRequirementAnswerViewOnly from "./widgets/AgencyRequirementAnswerViewOnly";

interface IAgenciesAnswerParams {
  entityId: string;
  standardId: string;
  programId: string;
}

interface IProps {
  menuElement: number;
  handleTabChange: (index: number) => void;
  activeTab: number;
  setActiveTab: (index: number) => void;
}

const AgenciesAnswer = ({
  menuElement,
  handleTabChange,
  activeTab,
  setActiveTab,
}: IProps) => {
  const { entityId, standardId, programId } =
    useParams() as IAgenciesAnswerParams;
  const { currentStage, stageForDashboard } = useContext(StageContext);
  const { loadPrevAgencyAnswer, newAgencyAnswer, createAnswerLoading } =
    useAnswerContext();
  const { getPrevAgencyAnswer } = usePrevAgencyAnswer();
  const { createAgencyAnswer } = useCreateAgencyAnswer({
    entityId,
    standardId,
    programId,
  });

  const isThirdStage = currentStage?.displayOrder === 3;
  const phase2StageId =
    currentStage?.stageIds && (currentStage?.stageIds[1] as number | undefined);
  const phase1StageId =
    currentStage?.stageIds && (currentStage?.stageIds[0] as number | undefined);

  const requirementsTitle = isThirdStage
    ? "المتطلبات المرجعة في مرحلة اعادة فتح النظام"
    : "المتطلبات";

  const isConfidentialAgency = newAgencyAnswer.isConfidentialAgency;
  const phase3Title = !isConfidentialAgency ? "التحقق  الأولى" : "مرحلة التحقق";
  const agencyRequirementAnswerViewOnlyTitle = !isConfidentialAgency
    ? "متطلبات التطبيق في مرحلة التحقق  الأولى"
    : "متطلبات التطبيق في مرحلة التحقق";
  const agencyFinalAnswerViewOnlyTitle = !isConfidentialAgency
    ? "اجابة الخبير في مرحلة التحقق  الأولى"
    : "اجابة الخبير في مرحلة التحقق";

  const { data: expertEvaluation } = useQuery<{
    result: IExpertEvaluation | null;
  }>({
    queryKey: [
      environment.getStandardExpertAnswer,
      phase2StageId,
      entityId,
      standardId,
    ],
    queryFn: ({ signal }) =>
      axiosGetRequest(
        environment.getStandardExpertAnswer,
        {
          standardId,
          agencyId: entityId,
          stageId: phase2StageId,
        },
        undefined,
        signal
      ),
    enabled: isThirdStage,
  });

  const special_Case = [9174, 9175];
  if (expertEvaluation?.result?.expertRequirementAnswers) {
    expertEvaluation.result.expertRequirementAnswers =
      expertEvaluation.result.expertRequirementAnswers.map((item) => {
        if (special_Case.includes(item.standardRequirementId)) {
          item.donotApply = false;
          item.decision = 0;
        }
        return item;
      });
  }

  const { data: stageOneAgencyAnswer } = useQuery<{
    result: IStageOneAgencyAnswer | null;
  }>({
    queryKey: [
      environment.getStandardAgencyAnswer,
      currentStage?.displayOrder,
      entityId,
      standardId,
    ],
    queryFn: ({ signal }) =>
      axiosGetRequest(
        environment.getStandardAgencyAnswer,
        {
          standardId,
          agencyId: entityId,
          stageId: phase1StageId,
        },
        undefined,
        signal
      ),
    enabled: isThirdStage,
  });

  const { data: standardRequirementsPhaseThree } = useQuery<{
    result: IStanderWithRequirement | null;
  }>({
    queryKey: [
      environment.getStandardWithRequirement,
      currentStage?.displayOrder,
      entityId,
      standardId,
      programId,
    ],
    queryFn: ({ signal }) =>
      axiosGetRequest(
        environment.getStandardWithRequirement,
        {
          standardId,
          agencyId: entityId,
          stageId: currentStage?.id,
          surveyId: programId,
        },
        undefined,
        signal
      ),
    enabled: isThirdStage,
  });

  const isNotReopenedStander = !!(
    standardRequirementsPhaseThree?.result?.standardRequirements?.length === 0
  );

  const disableAgencyAnswerInPhase3 =
    isConfidentialAgency || isNotReopenedStander;

  useEffect(() => {
    if (currentStage && entityId) {
      getPrevAgencyAnswer(standardId, entityId, stageForDashboard);
    }
  }, [currentStage, entityId, stageForDashboard]);

  useEffect(() => {
    if (isThirdStage) {
      if (isConfidentialAgency) {
        setActiveTab(0);
      } else if (disableAgencyAnswerInPhase3) {
        setActiveTab(1);
      } else {
        setActiveTab(2);
      }
    }
  }, [
    isThirdStage,
    disableAgencyAnswerInPhase3,
    isConfidentialAgency,
    setActiveTab,
  ]);

  const requirmentsDefaultActiveTab = isConfidentialAgency
    ? 0
    : disableAgencyAnswerInPhase3
    ? 1
    : 2;

  return (
    <div className="w-full mx-2 bg-white mt-4 overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
      {menuElement === 0 && (
        <StandardAnswerData
          onNext={() => handleTabChange(1)}
          loading={loadPrevAgencyAnswer}
        />
      )}
      {loadPrevAgencyAnswer && menuElement !== 0 ? (
        <div className="min-h-[400px] flex justify-center items-center">
          <AppLoader />
        </div>
      ) : (
        <>
          {menuElement === 1 && (
            <>
              {!isThirdStage ? (
                <AgencyProofFiles
                  setMenuElement={handleTabChange}
                  standerRequirements={undefined}
                />
              ) : (
                <Tabs.TabList
                  defaultActiveTab={disableAgencyAnswerInPhase3 ? 0 : 1}
                >
                  <Tabs.Tab title="فتح النظام">
                    {newAgencyAnswer?.firstStageAnswer?.agencyProofAnswers && (
                      <AgencyProofFilesViewOnly
                        agencyProofAnswers={
                          newAgencyAnswer?.firstStageAnswer?.agencyProofAnswers
                        }
                      />
                    )}
                  </Tabs.Tab>
                  <Tabs.Tab
                    title="إعادة فتح النظام"
                    omit={disableAgencyAnswerInPhase3}
                  >
                    <AgencyProofFiles
                      setMenuElement={handleTabChange}
                      standerRequirements={
                        standardRequirementsPhaseThree?.result
                          ?.standardRequirements
                      }
                    />
                  </Tabs.Tab>
                </Tabs.TabList>
              )}
            </>
          )}
          {menuElement === 3 && (
            <>
              {!isThirdStage && (
                <StandardAnswerRequirement
                  label={requirementsTitle}
                  hideNextBtn={true}
                  setMenuElement={null}
                >
                  <AgencyRequirementAnswer isAgency />
                </StandardAnswerRequirement>
              )}
              {isThirdStage && (
                <>
                  <Tabs.TabList defaultActiveTab={requirmentsDefaultActiveTab}>
                    <Tabs.Tab title="فتح النظام">
                      <>
                        {stageOneAgencyAnswer?.result && (
                          <AgencyRequirementAnswerViewOnly
                            label="متطلبات التطبيق في مرحلة فتح النظام"
                            standardRequirements={trAgencyRequirementAnswer(
                              stageOneAgencyAnswer.result
                            )}
                          />
                        )}
                      </>
                    </Tabs.Tab>
                    <Tabs.Tab title={phase3Title} omit={isConfidentialAgency}>
                      {expertEvaluation?.result && (
                        <AgencyRequirementAnswerViewOnly
                          label={agencyRequirementAnswerViewOnlyTitle}
                          standardRequirements={trExpertRequirementAnswer({
                            ...expertEvaluation.result,
                            expertRequirementAnswers:
                              expertEvaluation.result.expertRequirementAnswers,
                          })}
                        />
                      )}
                    </Tabs.Tab>
                    <Tabs.Tab
                      title="إعادة فتح النظام"
                      omit={disableAgencyAnswerInPhase3}
                    >
                      {standardRequirementsPhaseThree?.result && (
                        <StandardAnswerRequirement
                          label={requirementsTitle}
                          hideNextBtn={true}
                          setMenuElement={null}
                        >
                          <AgencyRequirementAnswerStage3
                            standardRequirements={
                              standardRequirementsPhaseThree?.result
                                .standardRequirements
                            }
                            isAgency
                          />
                        </StandardAnswerRequirement>
                      )}
                    </Tabs.Tab>
                  </Tabs.TabList>
                </>
              )}
              <NextBtn onClick={() => handleTabChange(4)} />
            </>
          )}
          {/* Done */}
          {menuElement === 4 && (
            <>
              {!isThirdStage && (
                <AgencyFinalAnswer
                  setMenuElement={handleTabChange}
                  hideSaveBtn={isThirdStage}
                />
              )}
              {isThirdStage && (
                <>
                  <Tabs.TabList
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  >
                    <Tabs.Tab title="فتح النظام">
                      <>
                        {stageOneAgencyAnswer?.result && (
                          <AgencyFinalAnswerViewOnly
                            surveyDegrees={newAgencyAnswer.surveyDegrees}
                            selectedId={trAgencyFinalAnswer(
                              stageOneAgencyAnswer.result
                            )}
                          />
                        )}
                      </>
                    </Tabs.Tab>
                    <Tabs.Tab title={phase3Title} omit={isConfidentialAgency}>
                      {expertEvaluation?.result && (
                        <AgencyFinalAnswerViewOnly
                          label={agencyFinalAnswerViewOnlyTitle}
                          surveyDegrees={newAgencyAnswer.surveyDegrees}
                          selectedId={trExpertFinalAnswer(
                            expertEvaluation.result
                          )}
                        />
                      )}
                    </Tabs.Tab>
                    <Tabs.Tab
                      title="إعادة فتح النظام"
                      omit={disableAgencyAnswerInPhase3}
                    >
                      <AgencyFinalAnswer
                        setMenuElement={handleTabChange}
                        hideSaveBtn={isThirdStage}
                        label="إجابة الجهة في مرحلة إعادة فتح النظام"
                      />
                    </Tabs.Tab>
                  </Tabs.TabList>
                </>
              )}
              {isThirdStage && !disableAgencyAnswerInPhase3 && (
                <SaveBtn
                  onClick={() =>
                    createAgencyAnswer(standardId, entityId, currentStage?.id)
                  }
                  loading={createAnswerLoading}
                />
              )}
            </>
          )}
          {menuElement === 7 && (
            <StandardAnswerNotes
              createAnswer={undefined}
              isExpertAllowedToSendReAnswerRequest={undefined}
              createAnswerAsDraft={undefined}
              disableSave={undefined}
            >
              {expertEvaluation?.result && !isConfidentialAgency && (
                <AgencyNoteTemplate
                  expertEvaluation={expertEvaluation?.result}
                />
              )}
            </StandardAnswerNotes>
          )}
        </>
      )}
    </div>
  );
};

export default AgenciesAnswer;
