import DownloadButton from "../../components/DownloadButton";
import useFileDownloader from "../../hooks/usePdfLoader";

function DashboardButtons() {
  const { downloadPdf } = useFileDownloader();

  const handleDownloadMainStandards = async () => {
    const api = `${process.env.REACT_APP_URL}/Reports/DawnloadStandardsOfDigitalTransformation`;
    const filename = "وثيقة المعايير الأساسية للتحول الرقمي";
    const showError = false;

    await downloadPdf(api, null, filename, "", showError);
  };

  const handleDownloadStepsToShowStanders = async () => {
    const api = `${window.location.origin}/stepsToSeeStandersEvaluations.pdf`;
    const filename =
      "خطوات الاطلاع على نتيجة تقييم المعيار بعد مرحله التحقق الأولى، والإجابة على المعايير التي لم تحقق كامل المتطلبات";
    const showError = false;

    await downloadPdf(api, null, filename, "", showError);
  };

  return (
    <div className="w-full flex md:justify-end gap-5 px-10 mt-10 pb-2">
      {/* <DownloadButton
      disabled={true}
      loading={downloadPdfLoading}
      onClick={() => handleDownload(agencyId)}
    >
      {"تحميل التقرير النهائي (ملف PDf)"}
    </DownloadButton> */}

      <DownloadButton onClick={handleDownloadStepsToShowStanders}>
        خطوات الإطلاع على التقيم فى مرحلة التحقق الاولى
      </DownloadButton>
      <DownloadButton onClick={handleDownloadMainStandards}>
        تحميل وثيقة المعايير الأساسية
      </DownloadButton>
    </div>
  );
}

export default DashboardButtons;
