import { useQueryClient, useQuery } from "@tanstack/react-query";
import environment from "../../../../services/environment";
import { axiosGetRequest } from "../../../../services/Request";
import {
  expertId,
  pocOfficerId,
  reviewerId,
  surveyId,
} from "../../../../helpers/isPermission";
import { StageContext } from "../../../../contexts/stageContext";
import { useContext } from "react";

export const useGetAuditorExpertUnfinishedStandards = ({ currentPage }) => {
  // const { currentStage } = useContext(StageContext);
  const surveyStageId = localStorage.getItem("surveyStageId");
  const queryClient = useQueryClient();

  const { currentStage } = useContext(StageContext)
  const phase2StageId = currentStage?.stageIds?.[1];

  const {
    data,
    isLoading: isGettingAuditorExpertUnfinishedStandards,
  } = useQuery({
    queryKey: ["AuditorExpertUnfinishedStandards", currentPage, surveyStageId],
    queryFn: () =>
      axiosGetRequest(environment.getAuditorExpertUnfinishedStandards, {
        SurveyStageId: phase2StageId,
        SurveyId: surveyId(),
        ReviewerId: reviewerId,
        ExpertId: expertId,
        POCOfficerId: pocOfficerId,
        SkipCount: currentPage * 5,
        MaxResultCount: 5,
        StageId: phase2StageId,
      }),
    enabled: surveyStageId != null,
  });

  const unfinishedStandards = data?.result?.unReviewedAgencies;
  const allStandardsCount = data?.result?.totalAssignedAgenciesCount;
  const unfinishedStandardsCount = data?.result?.totalUnReviewedAgenciesCount;
  const totalPages = Math.ceil(unfinishedStandardsCount / 5);

  return {
    queryClient,
    unfinishedStandards,
    unfinishedStandardsCount,
    allStandardsCount,
    totalPages,
    data,
    isGettingAuditorExpertUnfinishedStandards,
  };
};
