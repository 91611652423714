import AnswerDegreeItem from "./answerDegreeItem";
import { useAnswerContext } from "../answerContext";
import {
  axiosGetRequest,
  axiosPostRequest,
} from "../../../../../../services/Request";
import environment from "../../../../../../services/environment";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { useParams } from "react-router";
import { StageContext } from "../../../../../../contexts/stageContext";

const ReviewerAnswerDegrees = () => {
  const { newReviewerAnswer, reviewerDegree } = useAnswerContext();
  const { entityId, standardId, programId } = useParams();
  const { newAgencyAnswer } = useAnswerContext();
  const { currentStage } = useContext(StageContext);
  const isForthStage = currentStage?.stageNumber === 4;
  const phase2StageId = currentStage?.stageIds?.[1];
  const isConfidentialAgency = newAgencyAnswer.isConfidentialAgency;

  const { data: phaseOneAgencyAnswer } = useQuery({
    queryKey: ["prepareStandardForAnswer", standardId, entityId],
    queryFn: ({ signal }) =>
      axiosPostRequest(
        environment.prepareStandardForAnswer,
        {
          StandardId: standardId,
          AgencyId: entityId,
          StageId: currentStage?.stageIds?.[0],
        },
        null,
        true,
        true,
        false,
        signal
      ),
    enabled: !!isForthStage,
  });

  const { data: reviewerAnswerOnPhase2 } = useQuery({
    queryKey: ["getReviewerAnswer", entityId, phase2StageId, standardId],
    queryFn: () =>
      axiosGetRequest(environment.getReviewerAnswer, {
        agencyId: entityId,
        stageId: phase2StageId,
        standardId: standardId,
        expertId: localStorage.getItem("userId"),
      }),
    enabled: !!phase2StageId,
  });

  const { data: standardRequirementsPhaseThree } = useQuery({
    queryKey: [
      environment.getStandardWithRequirement,
      currentStage?.displayOrder,
      entityId,
      standardId,
      programId,
    ],
    queryFn: ({ signal }) =>
      axiosGetRequest(
        environment.getStandardWithRequirement,
        {
          standardId,
          agencyId: entityId,
          stageId: currentStage?.stageIds?.[2],
          surveyId: programId,
        },
        undefined,
        signal
      ),
    enabled: !!currentStage?.stageIds?.[2],
  });

  const isNotReopenedStander = !!(
    standardRequirementsPhaseThree?.result?.standardRequirements?.length === 0
  );

  const disableSecondAuditPhase = isNotReopenedStander && !isConfidentialAgency;


  if (isForthStage && !disableSecondAuditPhase) {
    return (
      <div className="bg-[#F9F9FF] p-3 mr-3 rounded-b-[12px] mb-3">
        <div className="bg-white p-3 px-5 flex flex-wrap">
          <AnswerDegreeItem
            degree={
              phaseOneAgencyAnswer?.result?.surveyAgencyAnswer
                ?.finalCommitmentLevel?.title || "لا يوجد"
            }
            circleColor={
              phaseOneAgencyAnswer?.result?.surveyAgencyAnswer
                ?.finalCommitmentLevel?.color || "#fff"
            }
            title="إجابة الجهة للمرحلة الأولى"
          />
          <AnswerDegreeItem
            degree={
              reviewerAnswerOnPhase2?.result?.lastAnswer
                ?.pocOfficerFinalCommitmentLevel?.title || "لا يوجد"
            }
            circleColor={
              reviewerAnswerOnPhase2?.result?.lastAnswer
                ?.pocOfficerFinalCommitmentLevel?.color || "#fff"
            }
            title="إجابة ضابط الاتصال لمرحلة التحقق  الأولى"
          />
          <AnswerDegreeItem
            degree={
              reviewerAnswerOnPhase2?.result?.lastAnswer?.expertSelfEvaluationCommitmentLevel.title
               || "لا يوجد"
            }
            circleColor={
              reviewerAnswerOnPhase2?.result?.lastAnswer?.expertSelfEvaluationCommitmentLevel?.color || "#fff"
            }
            title="إجابة الخبير لمرحلة التحقق  الأولى"
          />
          <AnswerDegreeItem
            degree={
              reviewerAnswerOnPhase2?.result?.lastAnswer
                ?.reviewerFinalCommitmentLevel?.title || "لا يوجد"
            }
            circleColor={
              reviewerAnswerOnPhase2?.result?.lastAnswer
                ?.reviewerFinalCommitmentLevel?.color || "#fff"
            }
            title="إجابة المدقق لمرحلة التحقق  الأولى"
          />
        </div>
        <div className="bg-[#F9F9FF] rounded-b-[12px] mb-3">
          <div className="bg-white p-3 px-5 flex flex-wrap">
            <AnswerDegreeItem
              degree={
                newReviewerAnswer?.agencyFinalCommitmentLevel?.title ||
                "لا يوجد"
              }
              circleColor={
                newReviewerAnswer?.agencyFinalCommitmentLevel?.color || "#fff"
              }
              title="إجابة الجهة  لمرحلة إعادة فتح النظام"
            />

            <AnswerDegreeItem
              degree={
                newReviewerAnswer?.pocOfficerFinalCommitmentLevel?.title ||
                "لا يوجد"
              }
              circleColor={
                newReviewerAnswer?.pocOfficerFinalCommitmentLevel?.color ||
                "#fff"
              }
              title="إجابة ضابط الاتصال لمرحلة التحقق  النهائي"
            />

            {/* <AnswerDegreeItem
                degree={
                  newReviewerAnswer?.expertFinalCommitmentLevel?.title || "لا يوجد"
                }
                circleColor={
                  newReviewerAnswer?.expertFinalCommitmentLevel?.color || "#fff"
                }
                title="إجابة الخبير لمرحلة التحقق  النهائي"
              /> */}

            <AnswerDegreeItem
              degree={
                newReviewerAnswer?.checkedReviewerDegree?.title || "لا يوجد"
              }
              circleColor={
                newReviewerAnswer?.checkedReviewerDegree?.color || "#fff"
              }
              title="إجابة المدقق لمرحلة التحقق  النهائي"
            />

            <AnswerDegreeItem
              degree={
                reviewerDegree?.reviewerSelfEvaluationCommitmentLevel?.title ||
                "لا يوجد"
              }
              circleColor={
                reviewerDegree?.reviewerSelfEvaluationCommitmentLevel?.color ||
                "#fff"
              }
              title="الدرجة المقترحة من النظام"
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-[#F9F9FF] p-3 mr-3 rounded-[12px] mb-3">
      <div className="bg-white p-3 px-5 flex flex-wrap rounded-[9px]">
        <AnswerDegreeItem
          degree={
            newReviewerAnswer?.agencyFinalCommitmentLevel?.title || "لا يوجد"
          }
          circleColor={
            newReviewerAnswer?.agencyFinalCommitmentLevel?.color || "#fff"
          }
          title="إجابة الجهة للمرحلة الأولى"
        />

        <AnswerDegreeItem
          degree={
            newReviewerAnswer?.pocOfficerFinalCommitmentLevel?.title ||
            "لا يوجد"
          }
          circleColor={
            newReviewerAnswer?.pocOfficerFinalCommitmentLevel?.color || "#fff"
          }
          title="إجابة ضابط الاتصال لمرحلة التحقق  الأولى"
        />

        {/* <AnswerDegreeItem
          degree={
            newReviewerAnswer?.expertFinalCommitmentLevel?.title || "لا يوجد"
          }
          circleColor={
            newReviewerAnswer?.expertFinalCommitmentLevel?.color || "#fff"
          }
          title="إجابة الخبير لمرحلة التحقق  الأولى"
        /> */}

        <AnswerDegreeItem
          degree={newReviewerAnswer?.checkedReviewerDegree?.title || "لا يوجد"}
          circleColor={
            newReviewerAnswer?.checkedReviewerDegree?.color || "#fff"
          }
          title="إجابة المدقق لمرحلة التحقق  الأولى"
        />

        <AnswerDegreeItem
          degree={
            reviewerDegree?.reviewerSelfEvaluationCommitmentLevel?.title ||
            "لا يوجد"
          }
          circleColor={
            reviewerDegree?.reviewerSelfEvaluationCommitmentLevel?.color ||
            "#fff"
          }
          title="الدرجة المقترحة من النظام"
        />
      </div>
    </div>
  );
};

export default ReviewerAnswerDegrees;
