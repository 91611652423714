import { useQueryClient, useQuery } from "@tanstack/react-query";
import environment from "../../../../../services/environment";
import { axiosGetRequest } from "../../../../../services/Request";
import {
  expertId,
  pocOfficerId,
  reviewerId,
} from "../../../../../helpers/isPermission";

export const useGetAgenciesByStandardId = ({
  currentPage,
  filterValue,
  reviewedStatus,
  standardId,
  maxResult,
  finalCommitmentLevelId,
  reopened,
  isDraft,
  sorting,
}) => {
  const surveyStageId = localStorage.getItem('surveyStageId')
  const queryClient = useQueryClient();

  const { data, isLoading: isGettingAllAgenciesByStandardId } = useQuery({
    queryKey: [
      "AgenciesByStandardId",
      currentPage,
      filterValue,
      standardId,
      reviewedStatus,
      maxResult,
      finalCommitmentLevelId,
      reopened,
      isDraft,
      sorting,
    ],
    queryFn: () =>
      axiosGetRequest(environment.getAgenciesByStandardId, {
        StageId: surveyStageId,
        standardId: standardId,
        SkipCount: currentPage * maxResult,
        MaxResultCount: maxResult,
        IsReviewed: reviewedStatus,
        KeyWord: filterValue || null,
        AssignedReviewerId: reviewerId,
        AssignedExpertId: expertId,
        AssignedPOCOfficerId: pocOfficerId,
        finalCommitmentLevelId,
        reopened,
        isDraft,
        sorting,
      }),
      enabled: surveyStageId != null
  });

  const allAgenciesByStandardId = data?.result?.result;
  const totalCount = data?.result?.totalItemCountFilterd;
  const totalPages = Math.ceil(totalCount / maxResult);
  const allItemsCount = data?.result?.totalItemCountNotFilterd;
  const totalReviewedCount = data?.result?.totalReviewed;
  const totalNotReviewedCount = data?.result?.totalNotReviewed;
  const totalNotReviewedByExpert = data?.result?.totalNotReviewedByExpert;
  const totalNotReviewedByPoc = data?.result?.totalNotReviewedByPoc;
  const totalNotReviewedByReviewer = data?.result?.totalNotReviewedByReviewer;
  const totalReviewedByExpert = data?.result?.totalReviewedByExpert;
  const totalReviewedByPoc = data?.result?.totalReviewedByPoc;
  const totalReviewedByReviewer = data?.result?.totalReviewedByReviewer;


  return {
    queryClient,
    allAgenciesByStandardId,
    totalCount,
    totalPages,
    data,
    isGettingAllAgenciesByStandardId,
    totalReviewedCount,
    totalNotReviewedCount,
    allItemsCount,
    totalNotReviewedByExpert,
    totalNotReviewedByPoc,
    totalNotReviewedByReviewer,
    totalReviewedByExpert,
    totalReviewedByPoc,
    totalReviewedByReviewer,
  };
};
